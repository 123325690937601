var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "gallery-grid gallery-grid--" +
          (_vm.images.length <= 5 ? _vm.images.length : 5),
        { "gallery-grid--flex-height": _vm.flexHeight },
      ],
    },
    _vm._l(_vm.images.slice(0, 5), function (img, i) {
      return _c(
        "a",
        {
          key: i,
          staticClass: "gallery-grid__item",
          attrs: {
            href: _vm.$blobUrl + "/" + img.url,
            "data-caption": img.caption,
          },
        },
        [
          _c("img", {
            staticClass: "gallery-grid__img",
            attrs: { src: _vm.$blobUrl + "/" + img.url, alt: "" },
          }),
          _vm._v(" "),
          i == 4 && _vm.images.length > 5
            ? _c("div", { staticClass: "gallery-grid__more" }, [
                _c("span", { staticClass: "gallery-grid__more-text" }, [
                  _vm._v("+" + _vm._s(_vm.images.length - 5)),
                ]),
              ])
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }