<template>
  <div class="q-info__block">
    <div class="q-info__content">
      <strong class="q-info__content-heading">{{ quotationRequest.companyInfoTitle1 }}</strong>
      <p>{{ quotationRequest.companyInfoText1 }}</p>
      <strong class="q-info__content-heading">{{ quotationRequest.companyInfoTitle2 }}</strong>
      <p>{{ quotationRequest.companyInfoText2 }}</p>
      <strong class="q-info__content-heading">{{ quotationRequest.companyInfoTitle3 }}</strong>
      <p>{{ quotationRequest.companyInfoText3 }}</p>
      <strong class="q-info__content-heading">{{ quotationRequest.companyInfoTitle4 }}</strong>
      <p>{{ quotationRequest.companyInfoText4 }}</p>
      <strong class="q-info__content-heading">{{ quotationRequest.productDescriptionTitle }}</strong>
      <p>{{ quotationRequest.productDescription }}</p>
    </div>
    <div class="q-info__content">
      <gallery-grid
        :images="quotationRequest.companyImages"
        :flexHeight="true"
        v-if="quotationRequest.companyImages"
      ></gallery-grid>
    </div>
  </div>
</template>

<script>
import GalleryGrid from './gallery-grid.vue';
export default {
  components: { GalleryGrid },
  props: ['quotationRequest'],
};
</script>
