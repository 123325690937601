var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "q-info__block" }, [
    _c(
      "div",
      { staticClass: "q-info__content" },
      [
        _vm.quotationRequest.productImages
          ? _c("gallery-grid", {
              attrs: {
                images: _vm.quotationRequest.productImages,
                flexHeight: true,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "q-info__content q-info__qd" }, [
      _c("div", { staticClass: "q-info__qd-block" }, [
        _c("strong", { staticClass: "q-info__content-heading" }, [
          _vm._v("Product Information"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-info__qd-item" }, [
          _c("strong", [_vm._v("Production Process")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(_vm._s(_vm.quotationRequest.productProductionProcess)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-info__qd-item" }, [
          _c("strong", [_vm._v("Product Type")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.quotationRequest.productType))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-info__qd-item" }, [
          _c("strong", [_vm._v("Upper/lining materials")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.quotationRequest.productMaterialsUpper))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-info__qd-item" }, [
          _c("strong", [_vm._v("Outsole materials")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.quotationRequest.productMaterialsSole))]),
        ]),
        _vm._v(" "),
        _c("strong", { staticClass: "q-info__content-heading" }, [
          _vm._v("Production Standards"),
        ]),
        _vm._v(" "),
        _vm.quotationRequest.company.requirementsPhysicalFile ||
        _vm.quotationRequest.company.requirementsPhysicalText
          ? _c("div", { staticClass: "q-info__qd-item" }, [
              _c("strong", [
                _c("span", [_vm._v("Physical Requirements")]),
                _vm._v(" "),
                _vm.quotationRequest.company.requirementsPhysicalFile
                  ? _c(
                      "a",
                      {
                        staticClass: "q-info__qd-download",
                        attrs: {
                          href:
                            _vm.$blobUrl +
                            "/" +
                            _vm.quotationRequest.company
                              .requirementsPhysicalFile,
                          target: "_blank",
                        },
                      },
                      [_c("span", [_vm._v("Download")])]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.quotationRequest.company.requirementsPhysicalText
                ? _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.quotationRequest.company.requirementsPhysicalText
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.quotationRequest.company.requirementsChemicalFile ||
        _vm.quotationRequest.company.requirementsChemicalText
          ? _c("div", { staticClass: "q-info__qd-item" }, [
              _c("strong", [
                _c("span", [_vm._v("Chemical Restrictions")]),
                _vm._v(" "),
                _vm.quotationRequest.company.requirementsChemicalFile
                  ? _c(
                      "a",
                      {
                        staticClass: "q-info__qd-download",
                        attrs: {
                          href:
                            _vm.$blobUrl +
                            "/" +
                            _vm.quotationRequest.company
                              .requirementsChemicalFile,
                          target: "_blank",
                        },
                      },
                      [_c("span", [_vm._v("Download")])]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.quotationRequest.company.requirementsChemicalText
                ? _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.quotationRequest.company.requirementsChemicalText
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.quotationRequest.company.requirementsSocialFile ||
        _vm.quotationRequest.company.requirementsSocialText
          ? _c("div", { staticClass: "q-info__qd-item" }, [
              _c("strong", [
                _c("span", [_vm._v("Social Requirements")]),
                _vm._v(" "),
                _vm.quotationRequest.company.requirementsSocialFile
                  ? _c(
                      "a",
                      {
                        staticClass: "q-info__qd-download",
                        attrs: {
                          href:
                            _vm.$blobUrl +
                            "/" +
                            _vm.quotationRequest.company.requirementsSocialFile,
                          target: "_blank",
                        },
                      },
                      [_c("span", [_vm._v("Download")])]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.quotationRequest.company.requirementsSocialText
                ? _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.quotationRequest.company.requirementsSocialText
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.quotationRequest.company.requirementsEnvironmentalFile ||
        _vm.quotationRequest.company.requirementsEnvironmentalText
          ? _c("div", { staticClass: "q-info__qd-item" }, [
              _c("strong", [
                _c("span", [_vm._v("Environmental Requirements")]),
                _vm._v(" "),
                _vm.quotationRequest.company.requirementsEnvironmentalFile
                  ? _c(
                      "a",
                      {
                        staticClass: "q-info__qd-download",
                        attrs: {
                          href:
                            _vm.$blobUrl +
                            "/" +
                            _vm.quotationRequest.company
                              .requirementsEnvironmentalFile,
                          target: "_blank",
                        },
                      },
                      [_c("span", [_vm._v("Download")])]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.quotationRequest.company.requirementsEnvironmentalText
                ? _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.quotationRequest.company
                            .requirementsEnvironmentalText
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "q-info__qd-block" }, [
        _c("strong", { staticClass: "q-info__content-heading" }, [
          _vm._v("Quotation Information"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-info__qd-item" }, [
          _c("strong", [_vm._v("Quantity by article")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(_vm._s(_vm.quotationRequest.qiPlannedOrderQuantity)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-info__qd-item" }, [
          _c("strong", [_vm._v("Amount of Articles")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.quotationRequest.qiPlannedArticles))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-info__qd-item" }, [
          _c("strong", [_vm._v("Total Quantity")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.quotationRequest.qiTotalQuantity))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-info__qd-item" }, [
          _c("strong", [_vm._v("Target price FOB/FCA")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.quotationRequest.qiTargetPrice))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-info__qd-item" }, [
          _c("strong", [_vm._v("Size Range")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm.quotationRequest.qiSizeRangeFrom) +
                " - " +
                _vm._s(_vm.quotationRequest.qiSizeRangeTo)
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-info__qd-item" }, [
          _c("strong", [_vm._v("Date to recieve goods")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.quotationRequest.qiDateToReceiveGoods))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-info__qd-item" }, [
          _c("strong", [_vm._v("Shipping Destination")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.quotationRequest.qiShippingDestination))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-info__qd-item" }, [
          _c("strong", [_vm._v("Rough packing cost")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.quotationRequest.qiPackagingCost))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }