<template>
  <form class="q-offer" @submit.prevent="updateQuotation">
    <div class="q-offer__item q-offer__message">
      <strong class="q-offer__item-title">Message to Brand</strong>
      <div class="q-offer__item-controls">
        <div class="q-offer__item-control">
          <span class="q-offer__item-label">
            What do you want to promote? What makes you a great supplier for this project?
            <br />Clarify what kind of clients you are looking for.
          </span>
          <div class="q-offer__item-field">
            <textarea v-model="quotationData.messageFromSupplier" required :disabled="disableEdit"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="q-offer__item q-offer__message" v-if="!disableEdit || (quotationData.images || []).length">
      <strong class="q-offer__item-title">Pictures</strong>
      <div class="q-offer__item-controls">
        <div class="q-offer__item-control">
          <span class="q-offer__item-label">
            Have you developed/produced anything similar before?
            <br />Show the buyer you have relevant experience in producing their type of product.
          </span>

          <div class="quotation__file" v-for="(image, i) in quotationData.images" :key="i">
            <a class="quotation__file-link" :href="$blobUrl + '/' + image.url" target="_blank">
              {{ image.url.split('/')[1] }}
            </a>
            <span class="quotation__file-delete" title="Delete file" @click="removeImage(image)"></span>
          </div>
          <input
            type="file"
            class="quotation__input-file"
            placeholder="Upload file"
            @change="onFileChange"
            accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            v-if="!disableEdit"
          />
        </div>
      </div>
    </div>
    <div class="q-offer__form">
      <div class="q-offer__grid">
        <div class="q-offer__item">
          <div class="q-offer__item-icon" v-html="svg.iconSampleLT"></div>
          <strong class="q-offer__item-title">Sample L/T</strong>
          <div class="q-offer__item-controls">
            <div class="q-offer__item-control">
              <span class="q-offer__item-label">Correct material (if applicable)</span>
              <label class="q-offer__item-field">
                <input
                  style="width: 70%"
                  type="number"
                  class="q-offer__item-field-input text-centered"
                  v-model="quotationData.sampleLTCorrectMaterial"
                  required
                  :disabled="disableEdit"
                />
                <span class="q-offer__item-field-text">Days</span>
              </label>
            </div>
            <div class="q-offer__item-control">
              <span class="q-offer__item-label">Available material (if applicable)</span>
              <label class="q-offer__item-field">
                <input
                  style="width: 70%"
                  type="number"
                  class="q-offer__item-field-input text-centered"
                  v-model="quotationData.sampleLTAvailableMaterial"
                  required
                  :disabled="disableEdit"
                />
                <span class="q-offer__item-field-text">Days</span>
              </label>
            </div>
          </div>
        </div>
        <div class="q-offer__item">
          <div class="q-offer__item-icon" v-html="svg.iconOtherClients"></div>
          <strong class="q-offer__item-title">Other Clients</strong>
          <div class="q-offer__item-controls">
            <div class="q-offer__item-control">
              <span class="q-offer__item-label">What other brands are you producing?</span>
              <div class="q-offer__item-field">
                <input
                  type="text"
                  class="q-offer__item-field-input"
                  v-model="quotationData.otherBrandsProducing"
                  :disabled="disableEdit"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="q-offer__item">
          <div class="q-offer__item-icon" v-html="svg.iconPriceFobFca"></div>
          <strong class="q-offer__item-title">Price FOB/FCA</strong>
          <div class="q-offer__item-controls">
            <div class="q-offer__item-control">
              <span class="q-offer__item-label">Quoted without sample</span>
              <div
                class="q-offer__item-field q-offer__item-field--small"
                :class="{ 'q-offer__item-field--highlighted': fobIsHighlighted }"
              >
                <input
                  type="number"
                  class="q-offer__item-field-input text-centered"
                  v-model="quotationData.priceFOBFCA"
                  required
                  :disabled="disableEdit"
                  @input="clearCostBreakdown"
                  step="any"
                />
                <select class="q-offer__item-field-currency" v-model="quotationDataCurrency" :disabled="disableEdit">
                  <option v-for="currency in $store.currency.main" :value="currency" :key="currency.code">
                    {{ currency.symbol }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="q-offer__item">
          <div class="q-offer__item-icon" v-html="svg.iconSampleCharge"></div>
          <strong class="q-offer__item-title">Sample Charge</strong>
          <div class="q-offer__item-controls">
            <div class="q-offer__item-control">
              <span class="q-offer__item-label"
                >If any cost for sampling<br />
                Fixed price or based on FOB</span
              >
              <div class="q-offer__item-field q-offer__item-field--small">
                <input
                  type="number"
                  class="q-offer__item-field-input text-centered"
                  v-model="quotationData.sampleCost"
                  required
                  :disabled="disableEdit"
                  step="any"
                />
                <select class="q-offer__item-field-currency" v-model="quotationDataCurrency" :disabled="disableEdit">
                  <option v-for="currency in $store.currency.main" :value="currency">{{ currency.symbol }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="q-offer__item">
          <div class="q-offer__item-icon" v-html="svg.iconMoq"></div>
          <strong class="q-offer__item-title">Minimum order Quantity</strong>
          <div class="q-offer__item-controls">
            <div class="q-offer__item-control">
              <span class="q-offer__item-label">Point where no quantity upcharge is applied</span>
              <label class="q-offer__item-field q-offer__item-field--small">
                <input
                  type="number"
                  class="q-offer__item-field-input text-centered"
                  v-model="quotationData.moq"
                  required
                  :disabled="disableEdit"
                />
                <span class="q-offer__item-field-text">Pairs</span>
              </label>
            </div>
          </div>
        </div>
        <div class="q-offer__item">
          <div class="q-offer__item-icon" v-html="svg.iconProduction"></div>
          <strong class="q-offer__item-title">Production L/T</strong>
          <div class="q-offer__item-controls">
            <div class="q-offer__item-control">
              <span class="q-offer__item-label">From order placement until time of delivery</span>
              <label class="q-offer__item-field q-offer__item-field--small">
                <input
                  type="number"
                  class="q-offer__item-field-input text-centered"
                  v-model="quotationData.productionLT"
                  required
                  :disabled="disableEdit"
                />
                <span class="q-offer__item-field-text">Days</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="q-offer__item">
        <div class="q-offer__item-icon" v-html="svg.iconCostBreakdown"></div>
        <strong class="q-offer__item-title">Cost Breakdown</strong>
        <div class="q-offer__item-controls">
          <div class="q-offer__item-control">
            <span class="q-offer__item-label">How the cost structure is constituted</span>
            <div class="q-offer__item-table">
              <div class="q-offer__item-table-label q-offer__item-field-text">Materials</div>
              <div class="q-offer__item-field">
                <input
                  type="number"
                  class="q-offer__item-field-input"
                  v-model="quotationData.cbMaterials"
                  :disabled="disableEdit"
                  @input="changeFOB"
                  step="any"
                />
              </div>
              <div class="q-offer__item-table-label q-offer__item-field-text">Components</div>
              <div class="q-offer__item-field">
                <input
                  type="number"
                  class="q-offer__item-field-input"
                  v-model="quotationData.cbComponents"
                  :disabled="disableEdit"
                  @input="changeFOB"
                  step="any"
                />
              </div>
              <div class="q-offer__item-table-label q-offer__item-field-text">Labelling & Packaging</div>
              <div class="q-offer__item-field">
                <input
                  type="number"
                  class="q-offer__item-field-input"
                  v-model="quotationData.cbLabellingPackaging"
                  :disabled="disableEdit"
                  @input="changeFOB"
                  step="any"
                />
              </div>
              <div class="q-offer__item-table-label q-offer__item-field-text">Tooling</div>
              <div class="q-offer__item-field">
                <input
                  type="number"
                  class="q-offer__item-field-input"
                  v-model="quotationData.cbTooling"
                  :disabled="disableEdit"
                  @input="changeFOB"
                  step="any"
                />
              </div>
              <div class="q-offer__item-table-label q-offer__item-field-text">Labor costs</div>
              <div class="q-offer__item-field">
                <input
                  type="number"
                  class="q-offer__item-field-input"
                  v-model="quotationData.cbLaborCosts"
                  :disabled="disableEdit"
                  @input="changeFOB"
                  step="any"
                />
              </div>
              <div class="q-offer__item-table-label q-offer__item-field-text">Overheads</div>
              <div class="q-offer__item-field">
                <input
                  type="number"
                  class="q-offer__item-field-input"
                  v-model="quotationData.cbOverheads"
                  :disabled="disableEdit"
                  @input="changeFOB"
                  step="any"
                />
              </div>
              <div class="q-offer__item-table-label q-offer__item-field-text">Profit</div>
              <div class="q-offer__item-field">
                <input
                  type="number"
                  class="q-offer__item-field-input"
                  v-model="quotationData.cbProfit"
                  :disabled="disableEdit"
                  @input="changeFOB"
                  step="any"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="q-offer__footer" v-if="!disableEdit">
      <button type="submit" class="old-form__button">
        {{ mode == 'add' ? 'Submit' : 'Update' }} quotation request
      </button>
    </div>
  </form>
</template>

<script>
// SVG
import iconSampleLT from '../img/qo-sample-lt.svg';
import iconOtherClients from '../img/qo-other-clients.svg';
import iconPriceFobFca from '../img/qo-price-fob-fca.svg';
import iconSampleCharge from '../img/qo-sample-charge.svg';
import iconMoq from '../img/qo-moq.svg';
import iconProduction from '../img/qo-production.svg';
import iconCostBreakdown from '../img/qo-cost-breakdown.svg';

export default {
  props: ['mode', 'quotation', 'stage'],
  data() {
    return {
      svg: {
        iconSampleLT,
        iconOtherClients,
        iconPriceFobFca,
        iconSampleCharge,
        iconMoq,
        iconProduction,
        iconCostBreakdown,
      },
      quotationData: {
        userId: null,
        messageFromSupplier: null,
        sampleLTCorrectMaterial: null,
        currency: this.$store.currency.fallback,
        productionLT: null,
        priceFOBFCA: null,
        sampleLTAvailableMaterial: null,
        otherBrandsProducing: null,
        moq: null,
        sampleCost: null,
        cbMaterials: null,
        cbComponents: null,
        cbLabellingPackaging: null,
        cbTooling: null,
        cbLaborCosts: null,
        cbOverheads: null,
        cbProfit: null,
        images: [],
      },
      fobIsHighlighted: false,
      loadingFiles: [],
      addingQuotation: false,
    };
  },
  computed: {
    costBreakdown() {
      return {
        cbMaterials: +this.quotationData.cbMaterials,
        cbComponents: +this.quotationData.cbComponents,
        cbLabellingPackaging: +this.quotationData.cbLabellingPackaging,
        cbTooling: +this.quotationData.cbTooling,
        cbLaborCosts: +this.quotationData.cbLaborCosts,
        cbOverheads: +this.quotationData.cbOverheads,
        cbProfit: +this.quotationData.cbProfit,
      };
    },
    quotationRequestId() {
      return +this.$route.params.id || null;
    },
    disableEdit() {
      return this.stage > 3;
    },
    quotationDataCurrency: {
      get() {
        return this.$store.currency.main.find((c) => c.id === ((this.quotationData || {}).currency || {}).id);
      },
      set(newValue) {
        if (!this.quotationData) return;

        this.quotationData.currency = newValue;
      },
    },
  },
  methods: {
    changeFOB() {
      // Due to floating point number issue as detailed here, we need to do
      // toFixed and parseFloat it again:
      // https://stackoverflow.com/questions/1458633/how-to-deal-with-floating-point-number-precision-in-javascript
      this.quotationData.priceFOBFCA = parseFloat(
        (
          +this.quotationData.cbMaterials +
          +this.quotationData.cbComponents +
          +this.quotationData.cbLabellingPackaging +
          +this.quotationData.cbTooling +
          +this.quotationData.cbLaborCosts +
          +this.quotationData.cbOverheads +
          +this.quotationData.cbProfit
        ).toFixed(2)
      );
      this.fobIsHighlighted = true;
      setTimeout(() => {
        this.fobIsHighlighted = false;
      }, 200);
    },
    clearCostBreakdown() {
      this.quotationData.cbMaterials = null;
      this.quotationData.cbComponents = null;
      this.quotationData.cbLabellingPackaging = null;
      this.quotationData.cbTooling = null;
      this.quotationData.cbLaborCosts = null;
      this.quotationData.cbOverheads = null;
      this.quotationData.cbProfit = null;
    },
    async updateQuotation() {
      await Promise.all(this.loadingFiles);

      if (this.mode == 'add') {
        this.quotationData.userId = this.$store.user.current.id;

        if (this.addingQuotation) return;

        this.addingQuotation = true;

        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          this.addingQuotation = false;
          this.timeout = null;
        }, 5 * 1000);

        await this.$store.quotations.addQuotation(
          this.quotationRequestId,
          this.$store.user.company.id,
          this.quotationData
        );

        this.$emit('update');
      } else {
        await this.$store.quotations.updateQuotation(this.$store.user.company.id, this.quotationData);

        this.$notify({
          type: 'success',
          title: 'Quotation has been successfully updated',
        });
        this.$emit('update');
      }
    },
    // Below are stolen and slightly modifed from quotation-form
    fileToData(file) {
      if (!file) return null;
      let formData = new FormData();
      formData.append('file', file);
      return formData;
    },
    async onFileChange(event) {
      const input = event.target;

      if (!input.files) return;

      // Files are not true array, but actually FileList
      // type so need to first convert to proper array to
      // be able to run map on it.
      this.loadingFiles = this.loadingFiles.concat(
        Array.prototype.slice.call(input.files).map((file) => {
          const data = this.fileToData(file);

          return this.loadFile(data);
        })
      );
    },
    async loadFile(file) {
      const { file: img } = await this.$store.companies.uploadFile(file);

      this.quotationData.images.push({ url: img });
    },
    removeImage(imageToRemove) {
      this.quotationData.images = this.quotationData.images.filter((image) => image !== imageToRemove);
    },
  },
  mounted() {
    // REFACTOR: Should be in data method??
    if (this.quotation) {
      this.quotationData = this.quotation;
    }
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
.q-offer {
  margin: 0 auto;
  max-width: 1500px;
  &__form {
    display: flex;
    flex-wrap: wrap;
    margin-top: math.div(4.3rem, 1.6);
  }
  &__grid {
    width: 100%;
    @include tablet-landscape-min {
      width: 66.6666666%;
      display: flex;
      flex-wrap: wrap;
    }
    @include desktop-min {
      width: 75%;
    }
  }
  &__grid &__item {
    max-width: math.div(40rem, 1.6);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: math.div(4.2rem, 1.6);
    @include tablet-landscape-min {
      width: calc(50% - #{math.div(1.5rem, 1.6)});
      &:not(:nth-child(odd)) {
        margin-left: math.div(3rem, 1.6);
      }
    }
    @include desktop-min {
      width: calc(33.333333% - #{math.div(3rem, 1.6)});
      &:not(:nth-child(odd)) {
        margin-left: 0;
      }
      &:not(:nth-child(3n + 1)) {
        margin-left: math.div(3rem, 1.6);
      }
    }
  }
  &__grid + &__item {
    width: 100%;
    @include tablet-landscape-min {
      width: calc(33.3333333% - #{math.div(3rem, 1.6)});
      margin-left: math.div(3rem, 1.6);
    }
    @include desktop-min {
      width: calc(25% - #{math.div(3rem, 1.6)});
    }
  }
  &__item {
    width: 100%;
    text-align: center;
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: math.div(7rem, 1.6);
      height: math.div(7rem, 1.6);
      margin: 0 auto;
      border-radius: 50%;
      background: $c-light-gray;
      svg {
        display: block;
        width: 55%;
        height: 55%;
        fill: $c-dark;
        stroke-opacity: initial;
      }
    }
    &-title {
      display: block;
      margin-top: math.div(0.7rem, 1.6);
      font-size: math.div(2rem, 1.6);
      font-weight: normal;
    }
    &-controls {
      display: flex;
      margin-top: math.div(0.7rem, 1.6);
    }
    &-control {
      width: 100%;
      &:not(:first-child) {
        margin-left: math.div(2rem, 1.6);
      }
    }
    &-label {
      display: block;
      min-height: math.div(4rem, 1.6);
      opacity: 0.7;
      font-size: math.div(1.4rem, 1.6);
      line-height: math.div(2rem, 1.6);
    }
    &-field {
      width: 100%;
      display: flex;
      margin: math.div(1rem, 1.6) auto 0;
      transition: all 0.2s ease-in-out;
      &--small {
        width: 60%;
      }
      &--highlighted {
        box-shadow: 0 0 math.div(1rem, 1.6) rgba($c-dark, 0.5);
        transform: scale(1.05, 1.05);
      }
      &--small &-input {
        width: 70%;
      }
      & > * {
        width: 100%;
        &:not(:first-child) {
          border-left: 0;
        }
      }
      &-text {
        display: block;
        padding: math.div(1rem, 1.6);
        line-height: math.div(2rem, 1.6);
        height: math.div(4rem, 1.6);
        border: 1px solid rgba($c-gray, 0.5);
        font-size: math.div(1.2rem, 1.6);
        text-transform: uppercase;
        background: $c-light-gray;
      }
      &-currency {
        width: math.div(5rem, 1.6);
      }
      input,
      textarea,
      select {
        padding: math.div(1rem, 1.6);
        line-height: math.div(2rem, 1.6);
        height: math.div(4rem, 1.6);
        border: 1px solid rgba($c-gray, 0.5);
        background: $c-light;
        border-radius: 0;
        &.text-centered {
          text-align: center;
        }
      }
    }
    &-table {
      display: flex;
      flex-wrap: wrap;
      max-width: math.div(40rem, 1.6);
      margin: 0 auto;
      &-label {
        width: 65%;
        flex-shrink: 0;
        text-align: left;
        margin-top: 0;
        border-right: 0;
        &:first-child {
          margin-top: math.div(1rem, 1.6);
          & + div {
            margin-top: math.div(1rem, 1.6);
          }
        }
        &:not(:first-child) {
          border-top: 0;
          & + div input {
            border-top: 0;
          }
        }
        & + div {
          width: 35%;
          margin-top: 0;
          border-top: 0;
        }
      }
    }
  }
  &__message {
    width: 100%;
    max-width: math.div(40rem, 1.6);
    margin: 0 auto;
    @include desktop-min {
      width: calc(50% - #{math.div(3rem, 1.6)});
      max-width: 100%;
    }
    textarea {
      height: math.div(8rem, 1.6);
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
    padding-top: math.div(3rem, 1.6);
    .old-form__button {
      width: auto;
    }
  }
}
</style>
