<template>
  <section
    :class="[
      `gallery-grid gallery-grid--${images.length <= 5 ? images.length : 5}`,
      { 'gallery-grid--flex-height': flexHeight },
    ]"
  >
    <a
      :href="$blobUrl + '/' + img.url"
      class="gallery-grid__item"
      v-for="(img, i) in images.slice(0, 5)"
      :key="i"
      :data-caption="img.caption"
    >
      <img class="gallery-grid__img" :src="$blobUrl + '/' + img.url" alt />
      <div class="gallery-grid__more" v-if="i == 4 && images.length > 5">
        <span class="gallery-grid__more-text">+{{ images.length - 5 }}</span>
      </div>
    </a>
  </section>
</template>

<script>
import baguetteBox from 'baguettebox.js';
import objectFitImages from 'object-fit-images';

export default {
  name: 'gallery-grid',
  props: ['images', 'flexHeight'],
  mounted() {
    baguetteBox.run('.gallery-grid');
    objectFitImages('.gallery-grid img');
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'baguettebox.js/dist/baguetteBox.min.css';
.gallery-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  position: relative;
  &--flex-height {
    height: 100%;
  }
  @include mobile-only {
    height: calc(100vw - #{math.div(5rem, 1.6)});
  }
  @include tablet-min {
    min-height: math.div(60rem, 1.6);
  }
  &__item {
    display: flex;
    position: relative;
    background: $c-dark;
    cursor: pointer;
    overflow: hidden;
  }
  &__img {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  &__item:hover &__img {
    opacity: 0.8;
    transform: scale(1.05);
  }
  &__more {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($c-dark, 0.5);
    &-text {
      font-size: math.div(3rem, 1.6);
      color: $c-light;
    }
  }
  &--1 &__item {
    width: 100%;
    height: 100%;
  }
  &--2 &__item {
    width: 100%;
    height: calc(50% - #{math.div(0.5rem, 1.6)});
    &:nth-child(2) {
      margin-top: math.div(1rem, 1.6);
    }
  }
  &--3 &__item {
    width: 100%;
    height: calc(50% - #{math.div(0.5rem, 1.6)});
    &:nth-child(n + 2) {
      width: calc(50% - #{math.div(0.5rem, 1.6)});
      margin-top: math.div(1rem, 1.6);
    }
  }
  &--4 &__item {
    width: calc(50% - #{math.div(0.5rem, 1.6)});
    height: calc(50% - #{math.div(0.5rem, 1.6)});
    &:nth-child(n + 3) {
      margin-top: math.div(1rem, 1.6);
    }
  }
  &--5 &__item {
    width: calc(50% - #{math.div(0.5rem, 1.6)});
    height: calc(50% - #{math.div(0.5rem, 1.6)});
    &:nth-child(n + 3) {
      margin-top: math.div(1rem, 1.6);
    }
    &:nth-child(n + 3) {
      width: calc(33.3333333% - 6.6666667px);
    }
  }
}

// Baguette lightbox
#baguetteBox-overlay {
  z-index: 99999999 !important;
}
.baguetteBox-button {
}
</style>
