<template>
  <div class="q-info__block">
    <div class="q-info__content">
      <gallery-grid
        :images="quotationRequest.productImages"
        :flexHeight="true"
        v-if="quotationRequest.productImages"
      ></gallery-grid>
    </div>
    <div class="q-info__content q-info__qd">
      <div class="q-info__qd-block">
        <strong class="q-info__content-heading">Product Information</strong>
        <div class="q-info__qd-item">
          <strong>Production Process</strong>
          <p>{{ quotationRequest.productProductionProcess }}</p>
        </div>
        <div class="q-info__qd-item">
          <strong>Product Type</strong>
          <p>{{ quotationRequest.productType }}</p>
        </div>
        <div class="q-info__qd-item">
          <strong>Upper/lining materials</strong>
          <p>{{ quotationRequest.productMaterialsUpper }}</p>
        </div>
        <div class="q-info__qd-item">
          <strong>Outsole materials</strong>
          <p>{{ quotationRequest.productMaterialsSole }}</p>
        </div>
        <strong class="q-info__content-heading">Production Standards</strong>
        <div
          class="q-info__qd-item"
          v-if="quotationRequest.company.requirementsPhysicalFile || quotationRequest.company.requirementsPhysicalText"
        >
          <strong>
            <span>Physical Requirements</span>
            <a
              v-if="quotationRequest.company.requirementsPhysicalFile"
              class="q-info__qd-download"
              :href="$blobUrl + '/' + quotationRequest.company.requirementsPhysicalFile"
              target="_blank"
            >
              <span>Download</span>
            </a>
          </strong>
          <p v-if="quotationRequest.company.requirementsPhysicalText">
            {{ quotationRequest.company.requirementsPhysicalText }}
          </p>
        </div>
        <div
          class="q-info__qd-item"
          v-if="quotationRequest.company.requirementsChemicalFile || quotationRequest.company.requirementsChemicalText"
        >
          <strong>
            <span>Chemical Restrictions</span>
            <a
              v-if="quotationRequest.company.requirementsChemicalFile"
              class="q-info__qd-download"
              :href="$blobUrl + '/' + quotationRequest.company.requirementsChemicalFile"
              target="_blank"
            >
              <span>Download</span>
            </a>
          </strong>
          <p v-if="quotationRequest.company.requirementsChemicalText">
            {{ quotationRequest.company.requirementsChemicalText }}
          </p>
        </div>
        <div
          class="q-info__qd-item"
          v-if="quotationRequest.company.requirementsSocialFile || quotationRequest.company.requirementsSocialText"
        >
          <strong>
            <span>Social Requirements</span>
            <a
              v-if="quotationRequest.company.requirementsSocialFile"
              class="q-info__qd-download"
              :href="$blobUrl + '/' + quotationRequest.company.requirementsSocialFile"
              target="_blank"
            >
              <span>Download</span>
            </a>
          </strong>
          <p v-if="quotationRequest.company.requirementsSocialText">
            {{ quotationRequest.company.requirementsSocialText }}
          </p>
        </div>

        <div
          class="q-info__qd-item"
          v-if="
            quotationRequest.company.requirementsEnvironmentalFile ||
            quotationRequest.company.requirementsEnvironmentalText
          "
        >
          <strong>
            <span>Environmental Requirements</span>
            <a
              v-if="quotationRequest.company.requirementsEnvironmentalFile"
              class="q-info__qd-download"
              :href="$blobUrl + '/' + quotationRequest.company.requirementsEnvironmentalFile"
              target="_blank"
            >
              <span>Download</span>
            </a>
          </strong>
          <p v-if="quotationRequest.company.requirementsEnvironmentalText">
            {{ quotationRequest.company.requirementsEnvironmentalText }}
          </p>
        </div>
      </div>
      <div class="q-info__qd-block">
        <strong class="q-info__content-heading">Quotation Information</strong>
        <div class="q-info__qd-item">
          <strong>Quantity by article</strong>
          <p>{{ quotationRequest.qiPlannedOrderQuantity }}</p>
        </div>
        <div class="q-info__qd-item">
          <strong>Amount of Articles</strong>
          <p>{{ quotationRequest.qiPlannedArticles }}</p>
        </div>
        <div class="q-info__qd-item">
          <strong>Total Quantity</strong>
          <p>{{ quotationRequest.qiTotalQuantity }}</p>
        </div>
        <div class="q-info__qd-item">
          <strong>Target price FOB/FCA</strong>
          <p>{{ quotationRequest.qiTargetPrice }}</p>
        </div>
        <div class="q-info__qd-item">
          <strong>Size Range</strong>
          <p>{{ quotationRequest.qiSizeRangeFrom }} - {{ quotationRequest.qiSizeRangeTo }}</p>
        </div>
        <div class="q-info__qd-item">
          <strong>Date to recieve goods</strong>
          <p>{{ quotationRequest.qiDateToReceiveGoods }}</p>
        </div>
        <div class="q-info__qd-item">
          <strong>Shipping Destination</strong>
          <p>{{ quotationRequest.qiShippingDestination }}</p>
        </div>
        <div class="q-info__qd-item">
          <strong>Rough packing cost</strong>
          <p>{{ quotationRequest.qiPackagingCost }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GalleryGrid from './gallery-grid.vue';
export default {
  components: { GalleryGrid },
  props: ['quotationRequest'],
};
</script>
