var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "q-offer",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.updateQuotation($event)
        },
      },
    },
    [
      _c("div", { staticClass: "q-offer__item q-offer__message" }, [
        _c("strong", { staticClass: "q-offer__item-title" }, [
          _vm._v("Message to Brand"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-offer__item-controls" }, [
          _c("div", { staticClass: "q-offer__item-control" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "q-offer__item-field" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.quotationData.messageFromSupplier,
                    expression: "quotationData.messageFromSupplier",
                  },
                ],
                attrs: { required: "", disabled: _vm.disableEdit },
                domProps: { value: _vm.quotationData.messageFromSupplier },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.quotationData,
                      "messageFromSupplier",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      !_vm.disableEdit || (_vm.quotationData.images || []).length
        ? _c("div", { staticClass: "q-offer__item q-offer__message" }, [
            _c("strong", { staticClass: "q-offer__item-title" }, [
              _vm._v("Pictures"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "q-offer__item-controls" }, [
              _c(
                "div",
                { staticClass: "q-offer__item-control" },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _vm._l(_vm.quotationData.images, function (image, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "quotation__file" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "quotation__file-link",
                            attrs: {
                              href: _vm.$blobUrl + "/" + image.url,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(image.url.split("/")[1]) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "quotation__file-delete",
                          attrs: { title: "Delete file" },
                          on: {
                            click: function ($event) {
                              return _vm.removeImage(image)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  !_vm.disableEdit
                    ? _c("input", {
                        staticClass: "quotation__input-file",
                        attrs: {
                          type: "file",
                          placeholder: "Upload file",
                          accept:
                            ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf",
                        },
                        on: { change: _vm.onFileChange },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "q-offer__form" }, [
        _c("div", { staticClass: "q-offer__grid" }, [
          _c("div", { staticClass: "q-offer__item" }, [
            _c("div", {
              staticClass: "q-offer__item-icon",
              domProps: { innerHTML: _vm._s(_vm.svg.iconSampleLT) },
            }),
            _vm._v(" "),
            _c("strong", { staticClass: "q-offer__item-title" }, [
              _vm._v("Sample L/T"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "q-offer__item-controls" }, [
              _c("div", { staticClass: "q-offer__item-control" }, [
                _c("span", { staticClass: "q-offer__item-label" }, [
                  _vm._v("Correct material (if applicable)"),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "q-offer__item-field" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.quotationData.sampleLTCorrectMaterial,
                        expression: "quotationData.sampleLTCorrectMaterial",
                      },
                    ],
                    staticClass: "q-offer__item-field-input text-centered",
                    staticStyle: { width: "70%" },
                    attrs: {
                      type: "number",
                      required: "",
                      disabled: _vm.disableEdit,
                    },
                    domProps: {
                      value: _vm.quotationData.sampleLTCorrectMaterial,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.quotationData,
                          "sampleLTCorrectMaterial",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "q-offer__item-field-text" }, [
                    _vm._v("Days"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "q-offer__item-control" }, [
                _c("span", { staticClass: "q-offer__item-label" }, [
                  _vm._v("Available material (if applicable)"),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "q-offer__item-field" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.quotationData.sampleLTAvailableMaterial,
                        expression: "quotationData.sampleLTAvailableMaterial",
                      },
                    ],
                    staticClass: "q-offer__item-field-input text-centered",
                    staticStyle: { width: "70%" },
                    attrs: {
                      type: "number",
                      required: "",
                      disabled: _vm.disableEdit,
                    },
                    domProps: {
                      value: _vm.quotationData.sampleLTAvailableMaterial,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.quotationData,
                          "sampleLTAvailableMaterial",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "q-offer__item-field-text" }, [
                    _vm._v("Days"),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "q-offer__item" }, [
            _c("div", {
              staticClass: "q-offer__item-icon",
              domProps: { innerHTML: _vm._s(_vm.svg.iconOtherClients) },
            }),
            _vm._v(" "),
            _c("strong", { staticClass: "q-offer__item-title" }, [
              _vm._v("Other Clients"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "q-offer__item-controls" }, [
              _c("div", { staticClass: "q-offer__item-control" }, [
                _c("span", { staticClass: "q-offer__item-label" }, [
                  _vm._v("What other brands are you producing?"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "q-offer__item-field" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.quotationData.otherBrandsProducing,
                        expression: "quotationData.otherBrandsProducing",
                      },
                    ],
                    staticClass: "q-offer__item-field-input",
                    attrs: { type: "text", disabled: _vm.disableEdit },
                    domProps: { value: _vm.quotationData.otherBrandsProducing },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.quotationData,
                          "otherBrandsProducing",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "q-offer__item" }, [
            _c("div", {
              staticClass: "q-offer__item-icon",
              domProps: { innerHTML: _vm._s(_vm.svg.iconPriceFobFca) },
            }),
            _vm._v(" "),
            _c("strong", { staticClass: "q-offer__item-title" }, [
              _vm._v("Price FOB/FCA"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "q-offer__item-controls" }, [
              _c("div", { staticClass: "q-offer__item-control" }, [
                _c("span", { staticClass: "q-offer__item-label" }, [
                  _vm._v("Quoted without sample"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "q-offer__item-field q-offer__item-field--small",
                    class: {
                      "q-offer__item-field--highlighted": _vm.fobIsHighlighted,
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.quotationData.priceFOBFCA,
                          expression: "quotationData.priceFOBFCA",
                        },
                      ],
                      staticClass: "q-offer__item-field-input text-centered",
                      attrs: {
                        type: "number",
                        required: "",
                        disabled: _vm.disableEdit,
                        step: "any",
                      },
                      domProps: { value: _vm.quotationData.priceFOBFCA },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.quotationData,
                              "priceFOBFCA",
                              $event.target.value
                            )
                          },
                          _vm.clearCostBreakdown,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.quotationDataCurrency,
                            expression: "quotationDataCurrency",
                          },
                        ],
                        staticClass: "q-offer__item-field-currency",
                        attrs: { disabled: _vm.disableEdit },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.quotationDataCurrency = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      _vm._l(_vm.$store.currency.main, function (currency) {
                        return _c(
                          "option",
                          { key: currency.code, domProps: { value: currency } },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(currency.symbol) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "q-offer__item" }, [
            _c("div", {
              staticClass: "q-offer__item-icon",
              domProps: { innerHTML: _vm._s(_vm.svg.iconSampleCharge) },
            }),
            _vm._v(" "),
            _c("strong", { staticClass: "q-offer__item-title" }, [
              _vm._v("Sample Charge"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "q-offer__item-controls" }, [
              _c("div", { staticClass: "q-offer__item-control" }, [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "q-offer__item-field q-offer__item-field--small",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.quotationData.sampleCost,
                          expression: "quotationData.sampleCost",
                        },
                      ],
                      staticClass: "q-offer__item-field-input text-centered",
                      attrs: {
                        type: "number",
                        required: "",
                        disabled: _vm.disableEdit,
                        step: "any",
                      },
                      domProps: { value: _vm.quotationData.sampleCost },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotationData,
                            "sampleCost",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.quotationDataCurrency,
                            expression: "quotationDataCurrency",
                          },
                        ],
                        staticClass: "q-offer__item-field-currency",
                        attrs: { disabled: _vm.disableEdit },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.quotationDataCurrency = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      _vm._l(_vm.$store.currency.main, function (currency) {
                        return _c("option", { domProps: { value: currency } }, [
                          _vm._v(_vm._s(currency.symbol)),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "q-offer__item" }, [
            _c("div", {
              staticClass: "q-offer__item-icon",
              domProps: { innerHTML: _vm._s(_vm.svg.iconMoq) },
            }),
            _vm._v(" "),
            _c("strong", { staticClass: "q-offer__item-title" }, [
              _vm._v("Minimum order Quantity"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "q-offer__item-controls" }, [
              _c("div", { staticClass: "q-offer__item-control" }, [
                _c("span", { staticClass: "q-offer__item-label" }, [
                  _vm._v("Point where no quantity upcharge is applied"),
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass:
                      "q-offer__item-field q-offer__item-field--small",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.quotationData.moq,
                          expression: "quotationData.moq",
                        },
                      ],
                      staticClass: "q-offer__item-field-input text-centered",
                      attrs: {
                        type: "number",
                        required: "",
                        disabled: _vm.disableEdit,
                      },
                      domProps: { value: _vm.quotationData.moq },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotationData,
                            "moq",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "q-offer__item-field-text" }, [
                      _vm._v("Pairs"),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "q-offer__item" }, [
            _c("div", {
              staticClass: "q-offer__item-icon",
              domProps: { innerHTML: _vm._s(_vm.svg.iconProduction) },
            }),
            _vm._v(" "),
            _c("strong", { staticClass: "q-offer__item-title" }, [
              _vm._v("Production L/T"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "q-offer__item-controls" }, [
              _c("div", { staticClass: "q-offer__item-control" }, [
                _c("span", { staticClass: "q-offer__item-label" }, [
                  _vm._v("From order placement until time of delivery"),
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass:
                      "q-offer__item-field q-offer__item-field--small",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.quotationData.productionLT,
                          expression: "quotationData.productionLT",
                        },
                      ],
                      staticClass: "q-offer__item-field-input text-centered",
                      attrs: {
                        type: "number",
                        required: "",
                        disabled: _vm.disableEdit,
                      },
                      domProps: { value: _vm.quotationData.productionLT },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotationData,
                            "productionLT",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "q-offer__item-field-text" }, [
                      _vm._v("Days"),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "q-offer__item" }, [
          _c("div", {
            staticClass: "q-offer__item-icon",
            domProps: { innerHTML: _vm._s(_vm.svg.iconCostBreakdown) },
          }),
          _vm._v(" "),
          _c("strong", { staticClass: "q-offer__item-title" }, [
            _vm._v("Cost Breakdown"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "q-offer__item-controls" }, [
            _c("div", { staticClass: "q-offer__item-control" }, [
              _c("span", { staticClass: "q-offer__item-label" }, [
                _vm._v("How the cost structure is constituted"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "q-offer__item-table" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "q-offer__item-table-label q-offer__item-field-text",
                  },
                  [_vm._v("Materials")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "q-offer__item-field" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.quotationData.cbMaterials,
                        expression: "quotationData.cbMaterials",
                      },
                    ],
                    staticClass: "q-offer__item-field-input",
                    attrs: {
                      type: "number",
                      disabled: _vm.disableEdit,
                      step: "any",
                    },
                    domProps: { value: _vm.quotationData.cbMaterials },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotationData,
                            "cbMaterials",
                            $event.target.value
                          )
                        },
                        _vm.changeFOB,
                      ],
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "q-offer__item-table-label q-offer__item-field-text",
                  },
                  [_vm._v("Components")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "q-offer__item-field" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.quotationData.cbComponents,
                        expression: "quotationData.cbComponents",
                      },
                    ],
                    staticClass: "q-offer__item-field-input",
                    attrs: {
                      type: "number",
                      disabled: _vm.disableEdit,
                      step: "any",
                    },
                    domProps: { value: _vm.quotationData.cbComponents },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotationData,
                            "cbComponents",
                            $event.target.value
                          )
                        },
                        _vm.changeFOB,
                      ],
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "q-offer__item-table-label q-offer__item-field-text",
                  },
                  [_vm._v("Labelling & Packaging")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "q-offer__item-field" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.quotationData.cbLabellingPackaging,
                        expression: "quotationData.cbLabellingPackaging",
                      },
                    ],
                    staticClass: "q-offer__item-field-input",
                    attrs: {
                      type: "number",
                      disabled: _vm.disableEdit,
                      step: "any",
                    },
                    domProps: { value: _vm.quotationData.cbLabellingPackaging },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotationData,
                            "cbLabellingPackaging",
                            $event.target.value
                          )
                        },
                        _vm.changeFOB,
                      ],
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "q-offer__item-table-label q-offer__item-field-text",
                  },
                  [_vm._v("Tooling")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "q-offer__item-field" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.quotationData.cbTooling,
                        expression: "quotationData.cbTooling",
                      },
                    ],
                    staticClass: "q-offer__item-field-input",
                    attrs: {
                      type: "number",
                      disabled: _vm.disableEdit,
                      step: "any",
                    },
                    domProps: { value: _vm.quotationData.cbTooling },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotationData,
                            "cbTooling",
                            $event.target.value
                          )
                        },
                        _vm.changeFOB,
                      ],
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "q-offer__item-table-label q-offer__item-field-text",
                  },
                  [_vm._v("Labor costs")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "q-offer__item-field" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.quotationData.cbLaborCosts,
                        expression: "quotationData.cbLaborCosts",
                      },
                    ],
                    staticClass: "q-offer__item-field-input",
                    attrs: {
                      type: "number",
                      disabled: _vm.disableEdit,
                      step: "any",
                    },
                    domProps: { value: _vm.quotationData.cbLaborCosts },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotationData,
                            "cbLaborCosts",
                            $event.target.value
                          )
                        },
                        _vm.changeFOB,
                      ],
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "q-offer__item-table-label q-offer__item-field-text",
                  },
                  [_vm._v("Overheads")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "q-offer__item-field" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.quotationData.cbOverheads,
                        expression: "quotationData.cbOverheads",
                      },
                    ],
                    staticClass: "q-offer__item-field-input",
                    attrs: {
                      type: "number",
                      disabled: _vm.disableEdit,
                      step: "any",
                    },
                    domProps: { value: _vm.quotationData.cbOverheads },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotationData,
                            "cbOverheads",
                            $event.target.value
                          )
                        },
                        _vm.changeFOB,
                      ],
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "q-offer__item-table-label q-offer__item-field-text",
                  },
                  [_vm._v("Profit")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "q-offer__item-field" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.quotationData.cbProfit,
                        expression: "quotationData.cbProfit",
                      },
                    ],
                    staticClass: "q-offer__item-field-input",
                    attrs: {
                      type: "number",
                      disabled: _vm.disableEdit,
                      step: "any",
                    },
                    domProps: { value: _vm.quotationData.cbProfit },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.quotationData,
                            "cbProfit",
                            $event.target.value
                          )
                        },
                        _vm.changeFOB,
                      ],
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      !_vm.disableEdit
        ? _c("div", { staticClass: "q-offer__footer" }, [
            _c(
              "button",
              { staticClass: "old-form__button", attrs: { type: "submit" } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.mode == "add" ? "Submit" : "Update") +
                    " quotation request\n    "
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "q-offer__item-label" }, [
      _vm._v(
        "\n          What do you want to promote? What makes you a great supplier for this project?\n          "
      ),
      _c("br"),
      _vm._v("Clarify what kind of clients you are looking for.\n        "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "q-offer__item-label" }, [
      _vm._v(
        "\n          Have you developed/produced anything similar before?\n          "
      ),
      _c("br"),
      _vm._v(
        "Show the buyer you have relevant experience in producing their type of product.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "q-offer__item-label" }, [
      _vm._v("If any cost for sampling"),
      _c("br"),
      _vm._v("\n              Fixed price or based on FOB"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }