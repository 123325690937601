var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "q-info__block" }, [
    _c("div", { staticClass: "q-info__content" }, [
      _c("strong", { staticClass: "q-info__content-heading" }, [
        _vm._v(_vm._s(_vm.quotationRequest.companyInfoTitle1)),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.quotationRequest.companyInfoText1))]),
      _vm._v(" "),
      _c("strong", { staticClass: "q-info__content-heading" }, [
        _vm._v(_vm._s(_vm.quotationRequest.companyInfoTitle2)),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.quotationRequest.companyInfoText2))]),
      _vm._v(" "),
      _c("strong", { staticClass: "q-info__content-heading" }, [
        _vm._v(_vm._s(_vm.quotationRequest.companyInfoTitle3)),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.quotationRequest.companyInfoText3))]),
      _vm._v(" "),
      _c("strong", { staticClass: "q-info__content-heading" }, [
        _vm._v(_vm._s(_vm.quotationRequest.companyInfoTitle4)),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.quotationRequest.companyInfoText4))]),
      _vm._v(" "),
      _c("strong", { staticClass: "q-info__content-heading" }, [
        _vm._v(_vm._s(_vm.quotationRequest.productDescriptionTitle)),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.quotationRequest.productDescription))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "q-info__content" },
      [
        _vm.quotationRequest.companyImages
          ? _c("gallery-grid", {
              attrs: {
                images: _vm.quotationRequest.companyImages,
                flexHeight: true,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }